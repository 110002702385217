import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import { fetchOneRecibo } from "@/api/recibos/recibos.js";
import store from "@/store/modules/usuario.store.js";
import dateLocaleEspanhol from "date-fns/locale/es";
import writtenNumber from "written-number";

export async function generateRecibo(recibo_id) {
  const empresa = await fetchEmpresa(store.state.empresa.id);
  const recibo = await fetchOneRecibo(recibo_id);
  const bodyPDF = await layoutEmpresa();
  const cabecalho = JSON.stringify(bodyPDF.cabecalhoRecibo);
  const cabecalhoRecibo2 = JSON.parse(cabecalho);
  return new Promise((resolve, reject) => {
    let docDefinition = {};

    let recibo_info = recibo;
    let documento;
    let descricao;
    descricao = recibo_info.concepto_descricao.slice(
      0,
      recibo_info.concepto_descricao.length - 0
    );

    documento = empresa.documento ? empresa.documento : "";

    recibo_info.moeda_sigla =
      recibo_info.moeda_id === 1
        ? "R$"
        : recibo_info.moeda_id === 2
          ? "G$"
          : "U$";
    recibo_info.empresa_nome = store.state.empresa.nome;
    recibo_info.usuario_nome = store.state.usuario.name;

    let fontItem = 12;

    let moeda_nome;

    if (recibo_info.moeda_sigla == "G$") {
      moeda_nome = "GUARANIES";
    }

    if (recibo_info.moeda_sigla == "R$") {
      moeda_nome = "REALES";
    }

    if (recibo_info.moeda_sigla == "U$") {
      moeda_nome = "DOLARES";
    }

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: "RECIBO DE CRÉDITO",

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text: "Nº.: " + recibo_info.numero_recibo,

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let cabecalho2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: "RECIBO DE CRÉDITO",

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text: "Nº.: " + recibo_info.numero_recibo,

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["auto", 200, "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 17,
              margin: [0, 10, 0, 0],
              text: "CREDITO ADELANTO",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, true, false],
              alignment: "center",
              fontSize: 15,
              margin: [0, 10, 0, 0],
              // fillColor: "#F5F5F5",
              text:
                "Monto: " +
                recibo_info.moeda_sigla +
                " " +
                (recibo_info.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(recibo_info.valor_recibo)
                  : Vue.filter("currency")(recibo_info.valor_recibo)),
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [20, 15, 20, 10],
              // fillColor: "#F5F5F5",
              text:
                "Recibi de " +
                recibo_info.quem_pagou +
                "\n el importe de " +
                writtenNumber(recibo_info.valor_recibo, {
                  lang: "es",
                }).toUpperCase() +
                " " +
                moeda_nome +
                "\n en concepto de " +
                descricao,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              border: [true, false, true, false],
              margin: [40, 25, 40, 40],
              // fillColor: "#F5F5F5",
              text:
                "_____________________________________\n" +
                recibo_info.quem_assina +
                "\n" +
                documento +
                "\n\n" +
                format(parseISO(recibo_info.data_recibo), "dd MMMM yyyy", {
                  locale: dateLocaleEspanhol,
                }),
              colSpan: 3,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [8, 0, 0, 0],
              border: [true, true, false, true],
              text: "ORIGEM: " + recibo_info.origem,
              bold: true,
            },
            {
              alignment: "right",
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [0, 0, 12, 0],
              border: [false, true, false, true],
              text: "RECIBO CLIENTE ",
              bold: true,
            },
            {
              alignment: "right",
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [0, 0, 8, 0],
              border: [false, true, true, true],
              text: "USUARIO: " + recibo_info.usuario_nome,
              bold: true,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let pula = {
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-40, -40, 0, -40],
              border: [false, false, false, false],
              text: "\n\n\n---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n\n\n\n",
            },
          ],
        ],
      },
    };

    let corpo2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["auto", 200, "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 17,
              margin: [0, 10, 0, 0],
              text: "CREDITO ADELANTO",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, true, false],
              alignment: "center",
              fontSize: 15,
              margin: [0, 10, 0, 0],
              // fillColor: "#F5F5F5",
              text:
                "Monto: " +
                recibo_info.moeda_sigla +
                " " +
                (recibo_info.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(recibo_info.valor_recibo)
                  : Vue.filter("currency")(recibo_info.valor_recibo)),
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [20, 15, 20, 20],
              // fillColor: "#F5F5F5",
              text:
                "Recibi de " +
                recibo_info.quem_pagou +
                "\n el importe de " +
                writtenNumber(recibo_info.valor_recibo, {
                  lang: "es",
                }).toUpperCase() +
                " " +
                moeda_nome +
                "\n en concepto de " +
                descricao,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              border: [true, false, true, false],
              margin: [40, 25, 40, 40],
              // fillColor: "#F5F5F5",
              text:
                "_____________________________________\n" +
                recibo_info.quem_assina +
                "\n" +
                documento +
                "\n\n" +
                format(parseISO(recibo_info.data_recibo), "dd MMMM yyyy", {
                  locale: dateLocaleEspanhol,
                }),
              colSpan: 3,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [8, 0, 0, 0],
              border: [true, true, false, true],
              text: "ORIGEM: " + recibo_info.origem,
              bold: true,
            },
            {
              alignment: "right",
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [0, 0, 12, 0],
              border: [false, true, false, true],
              text: "RECIBO EMPRESA ",
              bold: true,
            },
            {
              alignment: "right",
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [0, 0, 8, 0],
              border: [false, true, true, true],
              text: "USUARIO: " + recibo_info.usuario_nome,
              bold: true,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let geral = [cabecalho, corpo, pula, cabecalho2, corpo2];

    docDefinition = {
      info: {
        title: "Recibo",
        author: "PH7",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
